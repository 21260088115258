
*, p {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

body, html {
    line-height: 1;
    background-color: #292929;
    font-size: 14px;
}

.font-bold {
    font-weight: 700;
}

a {
    text-decoration: none;
}

.bg-color {
    background-color: #404040;
}

.cursor-pointer {
    cursor: pointer;
}

.navigation {
    position: fixed;
    width: 80px;
    height: 100vh;
    background-color: #292929;
    border: 1px solid #000000;
}

    .navigation.home {
        position: relative;
        height: auto;
        margin-top: 64px;
        /* height: calc(100vh - 64px);
top: 64px; */
    }

.home-logo {
    background-color: #4F0B43;
    position: absolute;
    margin-left: 0px;
}

.navigation .nav-link {
    padding: 0.8rem 0.5rem;
}

    .navigation .nav-link span {
        display: block;
        font-size: 12px;
        line-height: 1.25rem;
        color: #fff;
    }

    .navigation .nav-link.active, .navigation .nav-link:hover {
        background-color: #434343;
    }

        .navigation .nav-link.active span {
            font-weight: 700;
        }

.nav-link .bi {
    color: #ffffff;
    position: absolute;
    right: 10px;
    top: 16px;
}

.nav-link.dropdown-btn.active .bi {
    transform: rotate(90deg);
}

.expand-nav {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 330px;
    background: #292929;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.25);
    z-index: 9;
}

    .expand-nav .nav > li > a {
        border-bottom: 1px solid #575757;
    }

    .expand-nav .nav > li ul {
        border-bottom: 1px solid #575757;
    }

    .expand-nav .nav li .active span {
        font-weight: 700;
    }

.body-section {
    margin-left: 80px;
    width: calc(100% - 80px);
}

.top-header-section {
    background-color: #4F0B43;
}

.banner-content {
    padding-top: 280px;
    color: #ffffff;
}

    .banner-content h1 {
        font-size: 80px;
        font-weight: 700;
        line-height: 73px;
        color: #ffffff;
    }

.cursole {
    background: linear-gradient(180deg,#4f0b43 10%,#191919);
    background-repeat: repeat-x;
}

.feature-content {
    background-image: url(../images/background-img.svg);
    background-repeat: repeat-x;
}


.header-section {
    background-color: #292929;
}

    .header-section .search {
        width: 500px;
        background: #404040;
        border: 1px solid #595959;
        border-radius: 6px;
    }

.form-control:focus {
    box-shadow: none;
}

.header-section .search .form-control {
    color: #ffffff;
}

/*Auto Complete*/
.autocomplete-items {
    position: fixed;
    background-color: #ffffff;
    width: 500px;
    max-height: 200px;
    overflow-y: auto;
}

    .autocomplete-items > div {
        padding: 10px;
    }

        .autocomplete-items > div:hover {
            background-color: #f1f2f3;
            cursor: pointer;
        }

.autocomplete-active {
    background-color: #f1f2f3;
}

.suggestion-section li a {
    background: #404040;
    border: 1px solid #404040;
    border-radius: 16px;
    font-size: 14px;
    padding: 10px 16px;
    text-decoration: none;
    color: #ffffff;
}

.dropdown-container {
    display: none;
    background-color: #262626;
    padding-left: 8px;
}

.form-control.filter {
    background: #404040;
    border: 1px solid #595959;
    border-radius: 3px;
    color: #ffffff;
    min-width: 100px;
}

.btn-white {
    background: #FFFFFF;
    border: 1px solid #595959;
    border-radius: 3px;
}

.video-section .video .bi {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9;
    cursor: pointer;
}

.btn-present {
    background: #404040;
    border: 1px solid #595959;
    border-radius: 3px;
}

.video-conatiner {
    height: calc(100vh - 275px);
}

.hover:hover {
    text-decoration: underline;
}

.textcolor {
    color: white;
    font-size: 13px;
    text-decoration: none;
}

    .textcolor a {
        color: white;
        font-size: 13px;
        text-decoration: none;
        text-align: center;
    }


.slick-track {
    position: absolute;
    top: 0;
    left: 0;
    display: flex !important;
    margin-left: 0 !important;
    margin-right: 0 !important
}

.header-Style {
    margin-bottom: 2% !important;
    padding-left: 0px !important;
}

.see-all-Style {
    padding-bottom: 13px;
}
.media-contents-r .slick-slide {
    height: 421px !important;
    min-height: 538px !important;
    width: 326px;
    width: 73% !important;
}

.media-contents-r
.slick-slide div {
    height: 280px !important;
    width: 97% !important;
    margin-bottom: 10px;
}
.media-contents-r
.slick-track {

    height:1000px !important
}
.media-contents-h .slick-slide {
    height: 160px !important;
}
.slick-slide {
    min-height: 250px !important;
}

    .slick-slide div {
        height: 85% !important;
        width: 98% !important;
        padding-left: 3px !important;
    }

    .slick-slide img {
        /* width:100%;
       margin:5%;*/
    }

.slick-next {
    margin-right: -2% !important;
}


.folder-bc {
    background-color: #292929 !important;
}

.active-folder {
    background-color: white !important;
    color: black !important;
}

.our-lab {
    float: left;
    padding-left: 5px
}

.our-lab a{
    padding-bottom: 15px
}

.active-folder img {
    background-color: black !important;
}

.discovery-filter {
    margin-bottom: 10px;
    padding-left: 10px !important;
}

.header-width-100 {
    width: 0% !important;
}

.sorter-video-content {
    width: 100% !important;
    height: 100% !important;
    border-radius: 5px !important;
    overflow: hidden;
    perspective: 1px
}

.video-content {
    border-radius: 5px;
    background-color: black;
}

.video-content video {
    height: 100% !important;
    background-color: black;
}

    .video-content2 video {
        height: 155px !important;
        width:150px !important;
        background-color: black;
    }

.play-agenda-h .video-content {
    border-radius: 5px;
    background-color: black;
    margin-left: 0%;
}

.top-right-icon-p {
    top: 0px;
    right: 0px;
    width: 30px;
    height: 30px;
    position: absolute;
}

.top-left-icon-p {
    top: 0px;
    left: 7%;
    width: 30px;
    height: 30px;
    position: absolute;
}

    .top-left-icon-p:hover {
        background-color: white
    }

.top-right-pin-icon-p {
    top: 3px;
    right: 3px;
    width: 20px;
    height: 20px;
    position: absolute;
    cursor: pointer;
}

.top-left-pin-icon-p {
    top: 3px;
    width: 20px;
    height: 20px;
    position: absolute;
    cursor: pointer;
}

.pin-icon-p {
    cursor: pointer;
}

.div-remove-selector {
    position: relative;
}


.agenda-create-popup {
    min-height: 200px;
    max-height: 520px;
    overflow-y: hidden
}

    .agenda-create-popup .slick-list {
        max-height: 160px !important;
    }

.remove-thumbnail-icon {
    top: 3px;
    position: absolute;
    cursor: pointer;
    margin-left: -15px
}


.shareduser {
    width: 100%;
    padding-left: 0px;
}

    .shareduser :first-child button {
        width: 100%;
        background-color: #404040;
        color: white
    }

    .shareduser :first-child div div {
        background-color: #404040;
        color: white
    }

    .shareduser div:nth-child(2) {
        background-color: #404040 !important;
        color: white !important;
        width: 100% !important;
    }

    .shareduser :first-child div div:nth-child(2) {
        width: auto !important;
    }

.shared-agenda-popup {
    min-height: 200px;
    max-height: 465px;
    overflow-y: auto
}
